import React from "react";
import logo from "../image/logo.png";
import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="container-fluid  bg-dark ">
      <div className="row Footer-bg">
        <div className="col-lg-3 ">
          {" "}
          <div className="mt-5">
            <img className="img-fluid footer-logo" src={logo} alt="img" />
            <p className="text-center"> DIGITAL SPARK</p>
          </div>
        </div>
        <div className="col-lg-2  text-center p-3 ">
          <h6 className="mt-4 footer-header-text">QUICK LINKS</h6>
          <div className="footer-link mt-3">
            <Link className="link-text" to="/Home">
              Home
            </Link>{" "}
            <br />
            <Link className="link-text" to="/Home">
              Our services
            </Link>
            <br />
            <Link className="link-text" to="/Home">
              Contact
            </Link>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="d-flex p-3 flex-column justify-content-center align-items-center">
            <h6 className="mt-4 text-white text-center footer-header-text">
              CONTACT
            </h6>
            <div className="mt-3 text-center">
              <span className="text-white">Phone: 7708817322</span> <br />
              <span className="text-white">
                Email: talktolsn2023@gmail.com
              </span>{" "}
              <br />
              <span className="text-white">
                Address: 132, TNagar, Chennai-638 106
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="d-flex p-3 flex-column justify-content-center align-items-center">
            <h6 className="mt-4 text-white footer-header-text">FOLLOW</h6>
            <div className="mt-3">
              <span className="d-flex gap-3 align-items-center">
                <i className="fa-brands fa-facebook icons"></i>
                <a className="link-text text-white" href="#">
                  Facebook
                </a>
              </span>
              <span className="d-flex gap-3 align-items-center mt-2">
                <i className="fa-brands fa-instagram icons insta"></i>
                <a className="link-text text-white" href="#">
                  Instagram
                </a>
              </span>
              <span className="d-flex gap-3 align-items-center mt-2">
                <i className="fa-brands fa-whatsapp icons"></i>
                <a className="link-text text-white" href="#">
                  WhatsApp
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
