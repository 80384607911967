import React, { useEffect, useState } from "react";
import "./Home.css";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../image/naveen.jpeg";
import logo1 from "../image/saravanaimg.jpeg";
import websiteIcons from "../image/webdevelope.png";
import seoImage from "../image/seoimg.png";
import googleImage from "../image/google-fotor-bg-remover-20240517235021.png";
import leadImage from "../image/leadgeneration.png";
import socialImage from "../image/social marketing.png";
import googleService from "../image/google service.png";
import Footer from "../Footer/Footer";
import { Button, Modal } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo2 from "../image/logo.png";

AOS.init();
const Home = () => {
  const [open, setOpen] = useState(false);
  const initialValue = {
    name: "",
    email: "",
    contactnumber: "",
  };
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contactnumber: Yup.string().required("ContactNumber is equired"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  useEffect(() => {
    const timer = setTimeout(() => setOpen(true), 6000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row header-div">
          <div className="col-lg-12 bg-img">
            <div className="header-img">
              <h5 className="text-white text-center">
                Say To Goodbye to loss Hello to result
              </h5>
              <p className="text-white text-center header-text mt-3">
                I support businesses in gaining 5 times the <br />
                profit through effective ads.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="row quotes-div">
        <div
          className=" col-lg-9  justify-content-center"
          style={{ backgroundColor: "red" }}
        >
          <p className="text-center quotes-text">
            Struggling to generate leads and sell your product or service?
            Transform <br /> your marketing efforts into measurable results with
            our Tailored Data-Driven Marketing Strategy.
          </p>
        </div>
      </div> */}
        {/* -------------Banner---------- */}
        <div className="container">
          <div className="row justify-content-center mt-4 ">
            <div className="col-lg-8">
              <h3
                className="text-center digital-text "
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                DIGITAL MARKETING SERVICES
              </h3>
              <p
                className="text-center head-para"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                our digital marketing company provides a good website for your
                business and we offer affordable and effective digital marketing
                plans. our motive is to improve your business through our
                digital services.
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4">
              <div
                className="banner-box div-bg p-3 mt-3"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid image-style "
                    src={websiteIcons}
                    alt="icon"
                  />
                </div>

                <h4 className="text-center head-text">
                  Web Development & Design
                </h4>
                <p className="banner-text mt-4">
                  We'll make you a website that fits your business perfectly.
                  It'll look great and help you get noticed online. Plus, it'll
                  be easy for people to find you on search engines. And we'll
                  add tools to bring in more customers too.We will create a
                  functional website that is customized for your business
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="banner-box p-3  mt-3"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid image-style"
                    src={seoImage}
                    alt="img"
                  />
                </div>
                <h4 className="text-center head-text">
                  Search Engine Optimization
                </h4>
                <p className="banner-text mt-4">
                  SEO is like giving your website a makeover so search engines
                  like Google can find it easily. By using the right words and
                  tricks, your Website gets ranked higher in search results.
                  That means more people see it when they search online, which
                  can bring in more visitors and customers
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="banner-box div-bg p-3 mt-3"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid image-style"
                    src={googleImage}
                    alt="image"
                  />
                </div>
                <h4 className="text-center head-text">Google Ads</h4>
                <p className="banner-text mt-4">
                  Google Ads can generate immediate results, helping you attract
                  customers and drive sales faster. Google Ads can help you
                  increase brand awareness, drive website traffic, and
                  ultimately grow your business. With Google Ads, you can aim
                  your ads at the right people with our location
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4">
              <div
                className="banner-box   p-3 mt-3"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid  image-style"
                    src={socialImage}
                    alt="image"
                  />
                </div>
                <h4 className="text-center head-text ">
                  Social Media Marketing
                </h4>
                <p className="banner-text mt-4">
                  Social media marketing is about promoting your business on
                  platforms like Facebook to reach more people. With Facebook
                  Ads, you can create targeted ads that show up in people's
                  feeds based on their interests and behaviors
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="banner-box div-bg p-3  mt-3"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid image-style"
                    src={googleService}
                    alt="img"
                  />
                </div>
                <h4 className="text-center head-text">Google Services</h4>
                <p className="banner-text mt-4">
                  Google Tag Manager is a tool that helps you manage and track
                  the people different elements on your website, like buttons or
                  forms. Google Analytics tracks who visits your site, what they
                  do there, and where they come from.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="banner-box  p-3  mt-3 "
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="img-fluid image-style"
                    src={leadImage}
                    alt="image"
                  />
                </div>
                <h4 className="text-center head-text">Lead Generation</h4>
                <p className="banner-text mt-4">
                  Lead generation is the process of attracting and converting
                  potential customers into interested prospects for your
                  business. It involves strategies and techniques aimed at
                  capturing the interest of individuals who have shown an
                  interest in your products or services.
                </p>
              </div>
            </div>
          </div>
          {/* -------choose--------- */}
          <div className="row justify-content-center choose-box  mt-4">
            <div
              className="col-lg-5 percent-text text-center"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <img className="img-fluid " src={logo2} alt="img" />
            </div>
            <div className="col-lg-5">
              <h2
                className="text-center mt-3 choose-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Why we can choose us
              </h2>
              <p className="banner-text">
                {" "}
                Our focus is on delivering tangible results for your business,
                whether it's increased website traffic, higher conversion rates,
                or improved brand awareness. We bring fresh and innovative ideas
                to the table, ensuring your digital marketing campaigns stand
                out in a crowded online space.
              </p>
            </div>
          </div>
        </div>
        {/* -----------------Objective--------------- */}
        <div className="row san mt-5 justify-content-center p-3">
          <div className="col-lg-5">
            {" "}
            <div className="mt-3">
              <h1
                className="header-mentor"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Know Your Mentor
              </h1>
              <hr className="divider" />
              <p className="mentor-text">
                I transform startups into high-profit ventures, consult brands
                for growth, and help them leverage the power of the internet to
                skyrocket profits and boost their bottom lines. Through
                data-driven marketing strategies, I have empowered 40+ brands in
                industries such as Real Estate, EdTech, IT, and E-Commerce to
                achieve an extraordinary X5 times ROI, resulting in remarkable
                business growth and substantial profit increases. I’m thrilled
                to share my knowledge and empower marketers of all levels to
                take their brands to the next level with paid advertising
                strategies.
              </p>

              <h2 className="text-white">Saravanan M</h2>
              <p className="mentor-text">
                Performence Marketer & Facebook and Google Ads Expert.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <img className="img-fluid mentor-img" src={logo1} alt="own-img" />
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <img className="img-fluid mentor-img" src={logo} alt="own-img" />
            </div>
            <div className="col-lg-5">
              <div className="mt-3">
                <hr className="divider" />
                <p className="mentor-text" style={{ marginTop: "70px" }}>
                  I transform startups into high-profit ventures, consult brands
                  for growth, and help them leverage the power of the internet
                  to skyrocket profits and boost their bottom lines. Through
                  data-driven marketing strategies, I have empowered 40+ brands
                  in industries such as Real Estate, EdTech, IT, and E-Commerce
                  to achieve an extraordinary X5 times ROI, resulting in
                  remarkable business growth and substantial profit increases.
                  I’m thrilled to share my knowledge and empower marketers of
                  all levels to take their brands to the next level with paid
                  advertising strategies.
                </p>

                <h2 className="text-white">Naveenkumar N</h2>
                <p className="mentor-text">Website Developer & SEO Expert.</p>
              </div>
            </div>
          </div>
        </div>
        {/* -----------------Form------------- */}
        <div className="row p-5 Form-div">
          <div className="col-lg-12  d-flex flex-column justify-content-center align-items-center">
            <p className="text-center">
              {" "}
              <sapn className="Form-headtext text-white">
                Ready To Take The Next Step?
              </sapn>{" "}
              <br />
              <span className="Form-text text-white">
                Fill out our inquiry form to get started!
              </span>
            </p>
            <button onClick={() => setOpen(true)} className="Form-button">
              Get Enquire Form
            </button>
          </div>
          <hr className="Form-divider" />
        </div>
        {/* ---------------------Modal------------ */}
        <div>
          <Modal
            title={<span className="modal-title">Enquire Form</span>}
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={false}
            style={{ marginTop: "50px" }}
          >
            <div>
              <Formik
                initialValues={initialValue}
                validationSchema={SignupSchema}
                onSubmit={(values) => {
                  console.log(values);
                }}
              >
                {({ handleSubmit, handleChange, errors, touched, values }) => (
                  <div class="form-container">
                    <>{console.log("nnnnnn", errors, values)}</>
                    <Form>
                      <label>Name</label>
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        onChange={handleChange}
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                      <label>Email</label>
                      <input
                        type="email"
                        placeholder="Enter Your Email"
                        onChange={handleChange}
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                      <label>Contact Number</label>
                      <input
                        type="text"
                        placeholder="Enter Your Number"
                        onChange={handleChange}
                        name="contactnumber"
                      />
                      <ErrorMessage
                        name="contactnumber"
                        component="div"
                        className="error"
                      />
                      <label>Comments</label>
                      <textarea placeholder="Enter Your Comments"></textarea>

                      <input type="submit" value="Submit" />
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </Modal>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
