// import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./Home/Home";
import logo from "./image/logo.png";
function App() {
  function closeNavbar() {
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarToggler.getAttribute("aria-expanded") === "true") {
      const navbarCollapse = document.querySelector(".navbar-collapse");
      navbarToggler.classList.add("collapsed");
      navbarToggler.setAttribute("aria-expanded", "false");
      navbarCollapse.classList.remove("show");
    }
  }
  return (
    <Router>
      <nav className="navbar navbar-expand-lg sticky-top bg-dark ">
        <div className="container p-3">
          <img
            className="img-fluid logo-img navbar-brand "
            src={logo}
            alt="logo"
          />

          <button
            className="navbar-toggler"
            // color="white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "white" }}
            ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ">
              <li className="nav-item ps-5">
                <Link
                  to="/Home"
                  className="nav-link text-white nav-text"
                  aria-current="page"
                  style={{ fontSize: "19px" }}
                  onClick={() => closeNavbar()}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item ps-5">
                <Link
                  to="/about"
                  className="nav-link text-white nav-text"
                  style={{ fontSize: "19px" }}
                >
                  Features
                </Link>
              </li>
              <li className="nav-item dropdown ps-5">
                <Link
                  to="/dropdown"
                  className="nav-link dropdown-toggle text-white nav-text"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ fontSize: "19px" }}
                >
                  Dropdown link
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/action" className="dropdown-item">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link to="/another-action" className="dropdown-item">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link to="/something-else" className="dropdown-item ">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ps-5">
                <Link
                  to="/service"
                  className="nav-link text-white nav-text"
                  style={{ fontSize: "19px" }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route path="/Home" element={<Home />} />
        {/* Add routes for other components */}
      </Routes>
    </Router>
  );
}

export default App;
